jQuery(document).ready(function($) {
  loadMorePosts.init();
  utility.init();

  require('./components/mobile-header.js')
  require('./components/accordion.js')
  require('./components/video.js')
  require('./components/slider.js')

});


var loadMorePosts = {
  init: function() {
    jQuery('#load-more').click(function(){
        loadMorePosts.loadMore();
    });
  },

  loadMore: function() {
    var loadContainer = jQuery('#load-container');
    var postCount = jQuery('#load-container .item').length;
    var totalPosts = loadContainer.data('total');
    var args = loadContainer.data('args');

    if(postCount >= totalPosts) {
      return;
    }

    var data = {
      action: 'load_more_posts',
      post_count: postCount,
      post_args: args,
      post_search: posts.s,
      current_taxonomy: posts.current_taxonomy,
      current_term: posts.current_term,
      current_child_term: posts.current_child_term,
    };

    jQuery.ajax({
      url: posts.ajaxurl,
      type: "POST",
      data: data,
      beforeSend: function () {

      // Request for posts

        jQuery('#load-more').addClass('loading');
      },
      complete: function () {

          // Request for posts received / response is being sent back to us

          jQuery('#load-more').removeClass('loading');
        },
        success: function(data) {

          // We get posts back / successful response

          if(data) {
            loadContainer.append(data);
          }

          postCount = jQuery('#load-container .item').length;

          if(postCount >= totalPosts) {
            jQuery('#load-more').replaceWith('<a class="no-posts button" href="#load-container">Back to top</a>');
          }

          utility.hashAnimate();

          return;
        }
    });
  }
}

// Smooth Scroll
var utility = {
  init: function() {
      utility.hashAnimate();
  },

  hashAnimate: function() {
    jQuery('a[href^="#"]').click(function(e) {
        e.preventDefault();

        var localHash = jQuery(this).attr('href');
        var scrollOffset = jQuery('header').outerHeight();

        if(jQuery(localHash).offset()) {
          jQuery("html, body").animate({
            scrollTop: jQuery(localHash).offset().top - scrollOffset - 140
          }, 200);
        }
    });
  }
}
