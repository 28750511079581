jQuery(function($) {
  $('#mobile-menu').click(function(){
    $('header').toggleClass('mobile-active');

    if($('header').hasClass('mobile-active')) {
      $('body, html').css('overflow-y', 'hidden');
    } else {
      $('body, html').css('overflow-y', 'auto');
    }
  });

  var itemHasChildren = $('.menu-item-has-children');
  itemHasChildren.append('<span class="expand-child-items"><i class="fas fa-caret-down"></i></span>')

  $('.expand-child-items').click(function () {
    $(this).toggleClass('active');
    $(this).parent('.menu-item-has-children').find('ul').first().toggleClass('active');
  });
});
