jQuery(function($) {
  $('.slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: false,
      prevNextButtons: true,
      wrapAround: true,
      imagesLoaded: true,
      contain: true
    }

    slider.flickity(config)
  })
})
